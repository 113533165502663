import { PortfolioItem } from "../Interfaces/PortfolioInterface";

export const PortfolioData: PortfolioItem[] = [
	{
		title: "YouTranscribe.com",
		url: "https://youtranscribe.com/",
		description:
			"A free AI-powered tool that offers YouTube to MP3 conversion, transcription, and summarization features. The use of cutting-edge AI sets it apart by delivering high accuracy and efficiency.",
	},
	{
		title: "SuperDoc",
		url: "https://superdoc.fayonation.com/",
		description:
			"A full-stack comprehensive CRM for doctors, built from scratch to streamline medical practice management. Fully customizable with working authentication, role-based access, and an integrated calendar for appointments.",
	},
	{
		title: "E-Commerce Store",
		url: "https://lekiosque.net/",
		description:
			"A full-stack online store featuring custom-built components for an enhanced shopping experience. Fully responsive, made exactly to the client’s design specifications, with working payments and currently live and selling.",
	},
	{
		title: "PixelShades.ma",
		url: "https://pixelshades.ma",
		description:
			"A React, HTML, and SCSS website showcasing various services including graphic design, web development, and more. A live website for an active company, fully responsive, multilingual, SEO optimized, and with top-tier page speed.",
	},
	{
		title: "White Spider",
		url: "https://white-spider-power-thrones.en.softonic.com/android?",
		description:
			"A 2D game that combines the Marvel Cinematic Universe and Game of Thrones in a humorous crossover adventure. The game is live and has an active audience who enjoy playing it.",
	},
	{
		title: "Masked Forest",
		url: "https://www.youtube.com/watch?v=5JMpWe3w8kI",
		description:
			"A solo-created Zelda-like adventure game developed in Unity C#, where players explore and solve mysteries in a dark forest. Although still in progress, it has a captivating story and a well-crafted game engine that could be reused for future projects.",
	},
];
