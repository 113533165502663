import styled from "styled-components";

export interface IconProps {
  alt: string;
  src: string;
}

export const Icon = ({ alt, src }: IconProps) => (
  <IconWrapper alt={alt} src={src} />
);

const IconWrapper = styled.img`
  margin-top: 40px;
  border-radius: 1.8vw;
  background: var(--color-main);
  fill: var(--color-main);
  width: 2vw;
  height: 2vw;
  padding: 1vw;
`;
