import React from "react";
import styled from "styled-components";
import { AboutMe } from "./sections/AboutMeComponent";
import { Experience } from "./sections/ExperienceComponent";
import { ProSkills } from "./sections/ProSkillsComponent";
import "./App.css";
import { Portfolio } from "./sections/PortfolioComponent";

function App() {
	return (
		<AppContainer>
			<AboutMe />
			<ContentFragment>
				<Experience />
				{/* <Education /> */}
				<ProSkills />
				<Portfolio />
			</ContentFragment>
		</AppContainer>
	);
}

const AppContainer = styled.div`
	display: flex;
`;

const ContentFragment = styled.div`
	width: 60vw;
	margin-left: 3.5vw;
`;

export default App;
