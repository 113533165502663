import React from "react";
import styled from "styled-components";
import skillsIcon from "../assets/skills_icon.svg";
import { skillsData } from "../data/skillsData";

export const ProSkills = () => {
  return (
    <Fragment>
      <Bar>
        <Icons alt="skillIcon" src={skillsIcon} />
        <Titles>SKILLS</Titles>
      </Bar>
      <Lines />
      <ChartsContainer>
        {skillsData &&
          skillsData.map((skill, i) => (
            <Space key={`skill${i}`}>
              <Skill>{skill.name}</Skill>
              <Chart>
                <ChartFill style={{ width: `${skill.percentage}%` }} />
              </Chart>
            </Space>
          ))}
      </ChartsContainer>
    </Fragment>
  );
};

const Fragment = styled.div`
  height: fit-content;
  margin-bottom: 50px;
`;

const Bar = styled.div`
  display: flex;
`;

const Titles = styled.p`
  font-size: 2.5vw;
  height: fit-content;
  letter-spacing: 0.2vw;
  margin: auto 0 0 1.5vw;
`;

const Icons = styled.img`
  border-radius: 1.8vw;
  background: var(--color-main);
  width: 2vw;
  height: 2vw;
  padding: 1vw;
`;

const Lines = styled.div`
  height: 1px;
  background-color: #575959;
  margin-top: 1vw;
`;

const Space = styled.div`
  /* margin-left: 2vw; */
  margin-top: 1vw;
`;

const Skill = styled.p`
  font-size: 1.5vw;
  margin: 0;
`;

const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  /* grid-column-gap: 15px; */
  width: 100%;
`;

const Chart = styled.div`
  width: 100%;
  height: 0.5vw;
  background-color: var(--color-chart-bg);
  border-radius: 0.3vw;
`;

const ChartFill = styled.div`
  height: 100%;
  background-color: var(--color-accent);
  border-radius: 0.3vw;
`;
