import React from "react";
import styled from "styled-components";
import experienceIcon from "../assets/experience_icon.svg";
import { experienceData } from "../data/experienceData";
import { Icon } from "../components/Icon";

export const Experience = () => {
  return (
    <Fragment>
      <Bar>
        <Icon alt="experienceIcon" src={experienceIcon} />
        <Titles>EXPERIENCE</Titles>
      </Bar>
      <Lines />
      {experienceData &&
        experienceData.map((data, i) => (
          <Contents key={`experience${i}`}>
            <Space1>
              <Date>{data.date}</Date>
              <Company>{data.company}</Company>
              <TechContainer>
                {data.technologies?.map((tech, j) => (
                  <Tech key={`tech${j}`}>{tech}</Tech>
                ))}
              </TechContainer>
            </Space1>
            <Space2>
              <Task>{data.task}</Task>
              <Brief>{data.brief}</Brief>
            </Space2>
          </Contents>
        ))}
    </Fragment>
  );
};

const Fragment = styled.div`
  height: fit-content;
`;

const Bar = styled.div`
  display: flex;
`;

const Titles = styled.p`
  font-size: 2.5vw;
  height: fit-content;
  letter-spacing: 0.2vw;
  margin: auto 0 0 1.5vw;
`;

const Lines = styled.div`
  height: 1px;
  background-color: #575959;
  margin-top: 1vw;
`;

const Contents = styled.div`
  display: flex;
  margin-top: 2vw;
`;

const Space1 = styled.div`
  width: 35%;
  height: fit-content;
`;

const Date = styled.p`
  font-size: 2vw;
  margin: 0;
`;

const Company = styled.p`
  font-size: 1.5vw;
  margin: 0;
`;

const TechContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  gap: 3px;
  margin: 0 4px 4px 0;
`;
const Tech = styled.p`
  color: grey;
  font-size: 1.5vw;
  border: 1px dashed lightgrey;
  margin: 0;
  padding: 0px 4px;
  display: inline-block;
`;

const Space2 = styled.div`
  width: 60%;
  margin-left: 5%;
  height: fit-content;
`;

const Task = styled.p`
  margin: 0;
  font-size: 2vw;
  text-transform: uppercase;
`;

const Brief = styled.p`
  font-family: lato, serif;
  font-size: 1.2vw;
  text-align: justify;
`;
