import profilPic from "../assets/profile_picture.webp";
import addressIcon from "../assets/address_icon.svg";
import phoneIcon from "../assets/phone_icon.svg";
import linkedInIcon from "../assets/linkedIn.svg";
import mailIcon from "../assets/mail_icon.svg";
import { useEffect, useRef } from "react";
import { aboutMeData } from "../data/aboutMeData";
import styled from "styled-components";

export const AboutMe = () => {
	const fragmentRef = useRef<HTMLDivElement>(document.createElement("div"));

	useEffect(() => {
		window.addEventListener("scroll", listener);
		return () => window.removeEventListener("scroll", listener);
	}, []);

	function listener() {
		const windowPos = document.body.offsetHeight - window.innerHeight;
		const currentPos = window.scrollY;
		const aboutMeMargin = parseFloat(window.getComputedStyle(fragmentRef.current).getPropertyValue("margin-top")) * 2;
		const leftWindow = document.body.offsetHeight - (fragmentRef.current.offsetHeight + aboutMeMargin);
		const currentPosPercentage = (currentPos * 100) / windowPos;
		const aboutMeNeededMargin = (currentPosPercentage * leftWindow) / 100;
		fragmentRef.current.style.top = aboutMeNeededMargin + "px";
	}

	return (
		<AboutContainer ref={fragmentRef}>
			<Profile alt="profilePic" src={profilPic} />
			<FullName>{aboutMeData.fullname}</FullName>
			<Profession>{aboutMeData.profession}</Profession>
			<Titles>ABOUT</Titles>
			<Lines />
			<Info>{aboutMeData.aboutme}</Info>
			{/* {aboutMeData.socialmedia.map((data, i) => (
        <div key={`social${i}`}>
          <SocialType>{data.type}</SocialType>
          <SocialLink
            href={`https://${data.link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.link}
          </SocialLink>
        </div>
      ))} */}
			<Titles>CONTACT</Titles>
			<Lines />
			<AboutIcon>
				<img src={addressIcon} alt="addressicon" />
			</AboutIcon>
			<Address>{aboutMeData.contact.address}</Address>
			<AboutIcon>
				<img src={phoneIcon} alt="phoneicon" />
			</AboutIcon>
			{aboutMeData.contact.phone.map((num, i) => (
				<SocialPhone key={`phone${i}`}>{num}</SocialPhone>
			))}

			<AboutIcon>
				<img src={mailIcon} alt="mailicon" />
			</AboutIcon>

			{aboutMeData.contact.mail.map((adrs: string, i: number) => (
				<Mail>
					<a href={`mailto:${adrs}`}>{adrs}</a>
				</Mail>
			))}

			<AboutIcon>
				<img src={linkedInIcon} alt="linkedin" />
			</AboutIcon>
			<Mail>
				<a href={`${aboutMeData.contact.linkedIn.link}`} target="_blank" rel="noreferrer">
					{aboutMeData.contact.linkedIn.title}
				</a>
			</Mail>
		</AboutContainer>
	);
};

const AboutContainer = styled.div`
	position: relative;
	margin: 2vw 0;
	margin-left: 3vw;
	width: 30%;
	background-color: #212836;
	border-radius: 3vw;
	padding: 1vw 0;
	height: fit-content;
`;

const Profile = styled.img`
	display: block;
	width: 20vw;
	height: 20vw;
	object-fit: cover;
	border-radius: 9vw;
	margin: 0 auto;
	transition: all 500ms ease;
	&:hover {
		margin-top: 30px;
		filter: brightness(1.2);
		transform: scale(1.2);
		cursor: none;
	}
`;
const ParagraphTag = styled.p`
	display: block;
	font-family: "nunito", sans-serif;
	color: white;
	font-size: 3vw;
	margin: 0 auto;
	width: fit-content;
`;

const FullName = styled(ParagraphTag)`
	margin: 2vw 2vw 0 2vw;
	text-align: center;
`;

const Profession = styled(ParagraphTag)`
	color: #da8131;
	font-size: 1.8vw;
	text-transform: uppercase;
	text-align: center;
`;

const Titles = styled(ParagraphTag)`
	font-size: 1.5vw;
	margin-top: 1vw;
	color: #ced3ee;
`;

const Lines = styled.div`
	background-color: #ced3ee;
	height: 1px;
	margin: 1vw 2vw 0 2vw;
`;

const Info = styled(ParagraphTag)`
	font-family: "lato", serif;
	font-size: 1.5vw;
	text-align: justify;
	margin: 2vw 2vw 0 2vw;
`;

const AboutIcon = styled.div`
	margin: 30px auto;
	background: var(--color-main);
	width: 3em;
	height: 3em;
	border-radius: 3em;
	padding: 0.8em;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		display: block;
		max-width: 1.6em;
		width: 1.6em;
		svg {
			max-width: 100%;
			width: 100%;
			fill: white;
		}
	}
`;

// const SocialType = styled(ParagraphTag)`
//   font-family: "nunito_bold";
//   font-size: 2vw;
//   margin-top: 3vw;
// `;

// const SocialLink = styled.a`
//   font-family: "lato";
//   font-size: 1.6vw;
//   color: white;
//   margin: 0 auto;
//   display: block;
//   width: fit-content;
// `;

const Address = styled(ParagraphTag)`
	font-family: "lato", serif;
	font-size: 1.5vw;
	margin-top: 1vw;
	text-align: center;
`;

const SocialPhone = styled(ParagraphTag)`
	font-family: "lato";
	font-size: 1.6vw;
	margin-top: 1vw;
`;

const Mail = styled(ParagraphTag)`
	font-family: "lato", serif;
	font-size: 1.5vw;
	margin-top: 1vw;
	text-align: center;

	a {
		text-decoration: none;
		color: white;
	}
`;
