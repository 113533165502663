import { AboutMeInterface } from "../Interfaces/AboutMeInterface";

export const aboutMeData: AboutMeInterface = {
	fullname: "Fayçal BEN LARBI DELAI",
	profession: "FULL-STACK ENGINEER",
	aboutme:
		"I am a Full Stack Developer with over 6 years of experience (excluding freelance), I am passionate, eager to learn and fast at it, hungry for problem solving by nature, I respect deadlines, can work alone or with a team depending on what is required, most productive in an agile-SCRUM managed enviroment.",
	socialmedia: [
		{
			type: "Linkedin",
			link: "linkedin.com/in/faycal-ben-larbi-delai/",
		},
	],

	contact: {
		address: "Marrakech, Morocco",
		phone: ["+212 615 886 330"],
		mail: ["info@fayonation.com"],
		linkedIn: {
			title: ".../faycal-ben-larbi-delai",
			link: "https://linkedin.com/in/faycal-ben-larbi-delai/",
		},
	},
};
