import { ExperienceInterface } from "../Interfaces/ExperienceInterface";

export const experienceData: ExperienceInterface[] = [
  {
    date: "2022 - Present",
    company: "Pixel Shades",
    technologies: [
      "ReactJS",
      "Typescript",
      "Angular",
      "Python",
      "Postgres",
      "Fast API",
      "NodeJs",
      "Git",
      "Linux",
    ],
    task: "Lead Full-stack engineer",
    brief:
      "Leading a small team of skilled individuals (designer, full-stack developer, sales engineer and a product manager) tasked with creating and maintaining various web portals, ERPs and CRMs, from idea to a concrete product actually used by customers.",
  },
  {
    date: "2023 - 2024",
    company: "YourCampus BV",
    technologies: ["ReactJS", "Typescript", "Docker", "Git"],
    task: "Front-end engineer",
    brief:
      "Tasked with supporting the sales and product teams by developping the company's websites and their web portal. This period involved rapidly adding numerous features to the web portal in coordination with the mobile and backend teams and revamping many of its aspects. This app is widely used by companies in the Netherlands and Germany for employee benefits.",
  },
  {
    date: "2021 - 2023",
    company: "Eyelevel.care",
    technologies: ["ReactJS", "Typescript", "React Native", "Git", "Expo"],
    task: "lead FRONT-END engineer",
    brief:
      "Responsible for 60% of the code produced in 2 medical apps, one web app 1 year in the making, and another already published mobile app used in Germany/Switzerland, following an already existing complex company code architecture.",
  },
  {
    date: "2018 - 2021",
    company: "SmartWeb Corp",
    task: "FULL-STACK ENGINEER",
    technologies: ["Figma", "Angular", "Ionic", "MySql", "Wordpress"],
    brief:
      "Developing PWAs for restaurants and Hotel management using React.js and its ecosystem, connecting to various APIs and data sources in a cloud environment, projects I created from scratch, then scaled and maintained till the end of my contract.",
  },
  {
    date: "2015 - 2018",
    task: "WEB DEVELOPER",
    company: "Freelancer.com",
    technologies: [
      "HTML5",
      "CSS3",
      "SASS",
      "JavaScript(ES6+)",
      "MySql",
      "MangoDB",
      "Wordpress",
      "Git",
      "Docker",
      "Unity 3D",
      "C#",
    ],
    brief:
      "First experiences working were based on self taught skills, which accumulated to produce various -but admittedly- small web apps, and more than 50 wordpress or pure HTML-CSS-JS websites, web scrapers and more...",
  },
  {
    date: "2013 - 2015",
    task: "Graphic designer",
    technologies: ["Sony Vegas", "Adobe Suite"],
    company: "UpWork.com",
    brief:
      "Creating app and websites assets, designing good UX, products and their packaging, 3D (modeling/rigging/animation) game assets, audiovisuals like 2d illustrations & animations and video edits for social media, ads or Youtube.",
  },
];
