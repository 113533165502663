import styled from "styled-components";
import portfolioIcon from "../assets/portfolio_icon.svg";
import { PortfolioData } from "../data/portfolioData";

export const Portfolio = () => {
	return (
		<Fragment>
			<Bar>
				<Icons alt="skillIcon" src={portfolioIcon} />
				<Titles>PORTFOLIO</Titles>
			</Bar>
			<Lines />
			<PortfolioWrapper>
				<PortfolioItems>
					{PortfolioData.map((item, index) => (
						<PortfolioItem key={index}>
							<ItemTitle>
								<a href={item.url} target="_blank" rel="noopener noreferrer">
									{item.title}
								</a>
							</ItemTitle>
							<Description>{item.description}</Description>
						</PortfolioItem>
					))}
				</PortfolioItems>
			</PortfolioWrapper>
		</Fragment>
	);
};

const PortfolioWrapper = styled.div`
	padding: 1rem;
	margin: 0 auto;
`;

const Icons = styled.img`
	border-radius: 1.8vw;
	background: var(--color-main);
	width: 2vw;
	height: 2vw;
	padding: 1vw;
`;

const PortfolioItems = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	gap: 1rem;
`;

const PortfolioItem = styled.div`
	background: #fff;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
	padding: 1.5rem;
	transition: transform 0.3s ease, box-shadow 0.3s ease;

	&:hover {
		transform: translateY(-5px);
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
	}
`;

const ItemTitle = styled.h3`
	font-size: 1.25rem;
	margin-bottom: 0.5rem;

	a {
		color: var(--color-main);
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
`;

const Description = styled.p`
	font-size: 1rem;
	color: #666;
`;

const Fragment = styled.div`
	height: fit-content;
	margin-top: 5vw;
`;

const Bar = styled.div`
	display: flex;
`;

const Titles = styled.p`
	font-size: 2.5vw;
	height: fit-content;
	letter-spacing: 0.2vw;
	margin: auto 0 0 1.5vw;
`;

const Lines = styled.div`
	height: 1px;
	background-color: #575959;
	margin-top: 1vw;
`;
